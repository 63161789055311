import './src/styles/global.css';

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAalsfHPi1F4UyuLZ8C3P0iV34-OaVdF3U",
  authDomain: "starbeat-msg.firebaseapp.com",
  databaseURL: "https://starbeat-msg.firebaseio.com",
  projectId: "starbeat-msg",
  storageBucket: "starbeat-msg.appspot.com",
  messagingSenderId: "70726994617",
  appId: "1:70726994617:web:0948dc552a0d27ed1de968"
};

// Initialize Firebase
window.app = initializeApp(firebaseConfig);
